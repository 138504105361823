import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Header } from '../components/global/Header';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from 'axios';


export const MainMenuView = () => {
	//global
	const Navigate = useNavigate();

	const cerrarSesion = async () => {
		const token = localStorage.getItem('token');
		const tokenus = localStorage.getItem('username');

		localStorage.removeItem('token');
		localStorage.removeItem('username');
		localStorage.removeItem('code');
		localStorage.removeItem('mail');
		localStorage.removeItem('DesRepresentante');


		Navigate("/login");

		try {
			const response = await axios.post('https://sincronizar.coopbetxi.com/ws/Logout.php', {
				'token': token,
			}, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

		} catch (error) {
			console.error('Error al invalidar el token', error);
		}
	};
	return (
		<div>
			<Header></Header>
			<div className='flex-container'>
				<div className='title'>
					Panel De Sincronización
				</div>
			</div>
			<div className='flex-container'>
				<div className='header-space'>
					<div className='flex'>
						<div className='button-main' onClick={() => Navigate("/socios")}>Clientes</div>
						{/* <div className='button-main' onClick={() => Navigate("/fincas")}>Fincas</div> */}
						<div className='button-main' onClick={() => Navigate("/Parcelas")}>Parcelas</div>
						<div className='button-main' onClick={() => Navigate("/Variedades")}>Variedades Parcelas</div>
						<div className='main-menu-button' onClick={() => cerrarSesion()}>
							<div className='flip'><LogoutIcon sx={{ fontSize: 40 }} /> </div>&nbsp;Cerrar sesion
						</div>
						{/* <div className='button-main' onClick={() => Navigate("/plantaciones")}>Plantaciones</div>  */}
					</div>
				</div>
			</div>
		</div>
	)
}
