import React, { useEffect, useState } from 'react';
import  axios  from 'axios';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { VariedadCard } from './VariedadCard';

export const VariedadesPorFinca = (idfinca) => {
 
//global
const Navigate = useNavigate();

//util
const [variedades, setVariedades] = useState([]);
const [loaded, setLoaded] = useState(false);
  // const [json, setJson] = useState(testjson);
const [update, setUpdate] = useState(false);
const [nameJson, setNameJson] = useState("");
const [añadir, setAñadir] = useState (false);
const [selectedFile, setSelectedFile] = useState ([]);
const [jsonData, setJsonData] = useState(null);
const 	[succesPost, setSuccesPost] = useState ([]);
const [ borrarSocios, setBorrarSocios] = useState (false);

const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
const autorization = ('gVk72OZIeYlMGKtAakYnBhkOxZtQdC0FeNX6KatpkSc=');
const dateString = ('2024/2/1411:13:31');

const param = useParams();
console.log(param);


  useEffect(() => {   
      loadvarieties();  
  }, [])

// useEffect(() => {
//   console.log("hi"); 
//   setUpdate(true);
//   setNameJson(testjson[0].nombre); 
// }, [testjson, setUpdate===true]);

//cargar L
const loadvarieties = () => {
  axios.get('https://wstest.ws.visual.visualnacert.com/vnwebservices/user/270929/v3/crops/16073/farms/'+param.id+'/farmsvarieties', {
  //  axios.get('http://ws-test.visualnacert.com/vnwebservices/user/84353/v3/crops/12253/farms', {
        headers: {
			'Authorization': 'g0pXrxd3PglJPthWoLUSIR+OHHNMcG3ST7dH7IOLWgQ=', 
            "Content-Type": "application/json",
			'dateString': '2023/9/318:54:42', 
		}
        })
   .then(function (response) {
     // manejar respuesta exitosa
     setVariedades(response.data.data);
	 setLoaded(true);	

   })
   .catch(function (error) {
     // manejar error
     console.log(error);
   })
   .finally(function () {
     // siempre sera executado
  });
}

//////Crear C
const addPersona = (plantacion) => {
	console.log("log persona",plantacion);

	let plantacionReady =  
	{
	"idTipo": plantacion.idTipo,
    "idOrganizacion":plantacion.idOrganizacion,
    "idPersonaExt": plantacion.idPersonaExt,
    "nombre": plantacion.Nombre,
    "apellidos":plantacion.Apellidos,
    "nid": plantacion.nid,
    "telefono1": plantacion.Telefono1,
    "telefono2": plantacion.Telefono2,
    "email":plantacion.Email,
    "numSocio":plantacion.numSocio,
    "codigoPersona":plantacion.codigoPersona,
    "idTipoAgricultor": plantacion.idTipoAgricultor,
    "idPais":null,
    "idComunidad":null,
    "idProvincia":null,
    "idMunicipio":null,
    "direccion":null,
    "idPersonaPadre":null,
    "numCarneRopo":plantacion.NumCarneRopo,
    "numCarneAplicador":plantacion.esAsesor,
    "fechaCaducidadCarne":plantacion,
    "esAsesor": plantacion.esAsesor
}

	axios.post('https://wstest.ws.visual.visualnacert.com/vnwebservices/user/270929/v3/persons',plantacionReady,
		{
			headers: {		
				'Authorization': 'g0pXrxd3PglJPthWoLUSIR+OHHNMcG3ST7dH7IOLWgQ=', 
				'dateString': '2023/9/318:54:42', 
				'Content-Type': 'application/json'
			}
		})
		.then(function (response) {
			// manejar respuesta exitosa
			console.log(response);
			if(response.data.resultado === "OK"){	
				setSuccesPost(succesPost)
			}				
		})
		.catch(function (error) {
			// manejar error
			console.log(error);
		})
		.finally(function () {
			// siempre sera executado
		});
	}

//////actualizar U
const actualizarPersona = (plantacion) => {
	console.log("log actualizar",plantacion)
  let idPersona = plantacion.idPersona;
  axios.post('https://wstest.ws.visual.visualnacert.com/vnwebservices/user/270929/v3/persons/'+idPersona,plantacion,
    {
    headers: {		
		'Authorization': 'g0pXrxd3PglJPthWoLUSIR+OHHNMcG3ST7dH7IOLWgQ=', 
		'dateString': '2023/9/318:54:42', 
       'Content-Type': 'application/json'
    }
  })
  .then(function (response) {
    // manejar respuesta exitosa
     console.log(response.data);
    if(response.data.resultado === "OK"){
    //   alert("Persona Actualizada correctamente");
    }				
  })
  .catch(function (error) {
    // manejar error
    console.log(error);
  })
  .finally(function () {
    // siempre sera executado
  });
}

/////eliminar D
const eliminarPersona = ( plantacion) => {
  let idPersona = plantacion.idPersona;
  let idOrganizacion = plantacion.idOrganizacion;
  console.log(idPersona, idOrganizacion);		
  
   axios.delete('https://wstest.ws.visual.visualnacert.com/vnwebservices/user/270929/v3/persons/'+idPersona,
   {
     "idPersona": idPersona,
     "idOrganizacion": idOrganizacion,
    headers: {
		'Authorization': 'g0pXrxd3PglJPthWoLUSIR+OHHNMcG3ST7dH7IOLWgQ=', 
		'dateString': '2023/9/318:54:42', 
      'Content-Type': 'application/json'
    }
  	})
   .then(function (response) {
     // manejar respuesta exitosa
     console.log(response);
     if(response.data.resultado === "OK"){
    //    alert("Persona con id: "+idPersona+" ha sido eliminado correctamente");
     }			
   })
   .catch(function (error) {
     // manejar error
     console.log(error);
   })
   .finally(function () {
     // siempre sera executado}
   });  
}

/// funcion para pasar el archivo json a un array de json desde el input file
const handleFileChange = (event) => {
    const file = event.target.files[0]; // Obtén el primer archivo seleccionado (puedes manejar múltiples archivos si lo deseas)
    
	if (file) {
		setSelectedFile(file);  
		const reader = new FileReader();  
		reader.onload = (e) => {
		  try {
			const parsedData = JSON.parse(e.target.result);
			setJsonData(parsedData);
		  } catch (error) {
			console.error('Error al analizar el archivo JSON:', error);
			setJsonData(null);
		  }
		};
  
		reader.readAsText(file);
	  } else {
		setSelectedFile(null);
		setJsonData(null);
	  }
	};
///funcion recorre el array y por cada json hace una peticion http post

const uploadList = () => {
	console.log(jsonData);
	jsonData.map((jdata, index ) =>	{
		const plantacionLoaded = variedades.find((plantacion) => plantacion.nid === jdata.nid);

		if (plantacionLoaded){
			actualizarPersona(plantacionLoaded)
		}else{
			addPersona(jdata)
		}
	}
	)
}

///funcion recorre el array y por cada json hace una peticion http post

const delList = () => {

	variedades.map((plantacion, index) => (
		eliminarPersona(plantacion)
	));

}
return (
  <div>
	<div className='flex-container'>			
				<div className='title'>
					Variedades Finca {param.id}
				</div>
		</div>
	<div className='button back' onClick={() => Navigate("/fincas")}>
		<KeyboardBackspaceIcon fontSize='large'/>&nbsp;Volver
	</div>  	
  <div className='flex-container'>	
  {(añadir===false) && (
	<>	
		<div className='button' onClick={() => setAñadir(true)}>
			Añadir Archivo Variedades
		</div>
		{(borrarSocios === false )&& (
			<div className='button' onClick={() => setBorrarSocios(true)}>
				Borrar todos las Variedades
			</div>
		)}
		{(borrarSocios === true )&& (
			< div className='button' onClick={() => delList()}>
				Confirmar Borrado de todos los Variedades
			</div>
		)}
	</>	 )}
	{(añadir===true) && (
		<div className='upluad-box'>
			<h4>
				Suelta aqui el archivo
			</h4>
			<div>
				<input id="datos" type="file" accept="application/JSON" onChange={(e) => handleFileChange(e)}></input>
			</div>
			{(jsonData !== null && (
				<div className='button' onClick={() => uploadList()} >
				Subir {jsonData.length} plantaciones a la base de datos
			</div>
			))}			
      	<div className='delete-person-button' onClick={() => setAñadir(false)}>
        	cancelar
      	</div>

		</div>
	)}   
  </div>
  <div className='flex-container'>
		<div className='header'>
			Listado de {variedades.length} plantaciones asociadas a la finca con id {param.id} en visual.
		</div>		
  	</div>		
	  {(loaded===true) && (<div className='cardsContainer'>
	  {variedades.map((plantacion, index) => (
					<div key={index}>
					  <div className='socioCard'>                    
						<VariedadCard data={plantacion} plantacion={plantacion}/> 
						<div className='flex-container'>
							  <div className='delete-person-button' onClick={() => eliminarPersona(plantacion)}>
								  <DeleteForeverIcon fontSize="medium"/>
							  </div>
						  </div> 
					  </div>      
				  </div> 			  
				))}
			{(variedades <= 0) && (
			<div className='socioCard'>                    
				<div className='header'>
					Parece Que no hay plantaciones asociadas a esta finca
				</div>  
			</div>

			)}	
            
      </div>
	 )}
	 {(loaded===false) && (
		 <div className='cardsContainer'> 
			 <div className='loading-spinner'> 			
			 </div>
		 </div>
	 )}
  </div>
)
}

