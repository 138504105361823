import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Element, ...rest }) => {
    // Obtener el token almacenado en localStorage
    const token = localStorage.getItem('token');

    // Verificar si el token está presente y es válido
    return token ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
