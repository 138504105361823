import React, { useEffect } from 'react'
import Notificacion from './Notificacion'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2';

export const BarraProgreso = ({ value, setValue, mensaje, setSHowLoadBar, setEstado, setAlertas, alertas }) => {

    useEffect(() => {
        if (value === 100) {
            const timeout = setTimeout(() => {
                Swal.fire({
                    title: mensaje,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
                setValue(0) 
                setEstado([])
                 setSHowLoadBar(false)
                 
            }, 500); // Mostrar el swal después de 2 segundos

            return () => clearTimeout(timeout); // Limpiar el timeout al desmontar el componente
        }
    }, [value]);


    return (
        <>
            <Box sx={{ width: '98%' }}>
                {value}%
                <LinearProgress color="success" variant="determinate" value={value} />
            </Box>
            {/* {value === 100 && (setValue(0), setEstado([]), setSHowLoadBar(false))} */}
        </>
    )
}
