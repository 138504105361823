import React, { useState } from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import { Header } from '../components/global/Header';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Swal from 'sweetalert2';



export const Login = ({ username, setUsername, password, setPassword }) => {

    //global
    const Navigate = useNavigate();

    //util
    const wsUrl = "sincronizar.coopbetxi.com";
    const [showPassword, setShowPassword] = React.useState(false);

    //value
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://' + wsUrl + '/ws/login.php', {
                username: username,
                password: password,
            });

            if (response.data.success) {
                // Guarda el token en el almacenamiento local o de sesión
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('code', response.data.CodReferencia);
                localStorage.setItem('username',  response.data.nombre); 
                localStorage.setItem('mail',  response.data.mail); 
                localStorage.setItem('DesRepresentante',  response.data.DesRepresentante); 


                Swal.fire({
                    icon: "success",
                    title: "Bienvenido/a \n" + username,
                    showConfirmButton: false,
                    timer: 1500
                  });
                Navigate('/');
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error de inicio de sesión: \n" +  response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                  });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };

    return (        
        <div>       
            <Header/>    
            <div className='flex-container'>
                <form>
                    <div className='login-card'>
                        <div className='login-icon'>
                            <LockPersonIcon sx={{ fontSize: 100 }} />
                        </div>
                        <div className='login-element'>
                            <PersonIcon sx={{ fontSize: 50 }} style={{ color: '#282c34' }} />&nbsp;
                            <TextField placeholder='Usuario' id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className='login-element'>
                            <VpnKeyIcon sx={{ fontSize: 50 }} style={{ color: '#282c34' }} />&nbsp;                            
                            <OutlinedInput
                                placeholder='password'
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                // Agrega este bloque onChange para actualizar el estado de la contraseña
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className='login-element'>
                            <div  className="return-button" onClick={handleLogin}>Iniciar sesión</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};