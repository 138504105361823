import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Socios } from '../components/socios/Socios';
import { Plantaciones } from '../components/plantaciones/Plantaciones';
import { Fincas } from '../components/fincas/Fincas';

import { PlantacionPorFinca } from '../components/plantaciones/PlantacionPorFinca';
import { MainMenuView } from '../pages/MainMenuView';
import { Parcelas } from '../components/parcelas/Parcelas';
import { Variedades } from '../components/plantaciones/variedades-fincas/Variedades';
import { VariedadesPorFinca } from '../components/plantaciones/variedades-fincas/VariedadesPorFinca';
import { Header } from '../components/global/Header';
import { Login } from '../pages/Login';
import PrivateRoute from './PrivateRoute';

import axios from 'axios';


const Router = () => {
	//global
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [tokenValido, setTokenValido] = useState(true);

	const verificarToken = async () => {
		const token = localStorage.getItem('token');

		if (!token) {
			// Si no hay token, el usuario ya está desconectado
			setTokenValido(false);
			return;
		}

		try {
			const response = await axios.post('https://sincronizar.coopbetxi.com/ws/VerificarToken.php', {
				token: token,

			}, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			const data = response.data;

			if (!data.success) {
				// Si el servidor indica que el token no es válido, marcarlo como no válido
				setTokenValido(false);
			}
		} catch (error) {
			console.error('Error al verificar el token:', error);
			// Manejar el error según sea necesario
		}
	};
	useEffect(() => {
		// Limpiar el intervalo al desmontar el componente
		verificarToken();
	}, [])

	return (
		<div className='back-main'>
			<BrowserRouter>
				<Routes>
					<Route element={<PrivateRoute />}>
						<Route path="/" element={<MainMenuView />}></Route>
						<Route path="/Socios" element={<Socios />}></Route>
						{/* <Route path="/plantaciones-finca/:id" element={<PlantacionPorFinca />}></Route> */}
						<Route path="/variedades-finca/:id" element={<VariedadesPorFinca />}></Route>
						{/* <Route path="/plantaciones" element={<Plantaciones />}></Route> */}
						<Route path="/Fincas" element={<Fincas />}></Route>
						<Route path="/Parcelas" element={<Parcelas />}></Route>
						<Route path="/Variedades" element={<Variedades />}></Route>
						<Route path="*" element={<Navigate to="/" replace />} />
					</Route>
					<Route path="/login" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default Router