import React, { useEffect, useState } from 'react'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FincaCard } from '../fincas/FincaCard';
import { Header } from '../global/Header';


export const Fincas = () => {

	//global
	const Navigate = useNavigate();

	//util
	const [fincas, setFincas] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [upadteJson, setUpdateJson] = useState([]);
	const [addJson, setAddJson] = useState([]);

	// const [json, setJson] = useState(testjson);
	const [update, setUpdate] = useState(false);
	const [nameJson, setNameJson] = useState("");
	const [añadir, setAñadir] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);
	const [jsonData, setJsonData] = useState(null);
	const [succesPost, setSuccesPost] = useState([]);
	const [borrarFincas, setBorrarFincas] = useState(false);


	const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
	const autorization = ('gVk72OZIeYlMGKtAakYnBhkOxZtQdC0FeNX6KatpkSc=');
	const dateString = ('2024/2/1411:13:31');



	useEffect(() => {
		loadFincas();
	}, [])

	// useEffect(() => {
	//   console.log("hi"); 
	//   setUpdate(true);
	//   setNameJson(testjson[0].nombre); 
	// }, [testjson, setUpdate===true]);

	//cargar L
	const loadFincas = () => {
		axios.get(url + "crops/16073/farms", {
			headers: {
				'Authorization': autorization,
				'dateString': dateString,
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				setFincas(response.data.data);
				console.log(response.data.data);
				setLoaded(true);

			})
			.catch((error) => {
				console.log(error);
			});
	}

	//////Crear C
	const addFinca = () => {
		addJson.map((finca, index) => {
			let socioReady =
			{
				"idFincaExt": finca.idFincaExt,
				"idProductor": finca.idProductor,
				"idOrganizacion": finca.idOrganizacion,
				"idTipoAgricultor": finca.idTipoAgricultor,
				"idPais": finca.idPais,
				"idComunidad": finca.idComunidad,
				"idProvincia": finca.idProvincia,
				"idMunicipio": finca.idMunicipio,
				"finca": finca.finca
			}
			axios.post(url + 'crops/16073/farms', socioReady,
				{
					headers: {
						'Authorization': autorization,
						'dateString': dateString,
						'Content-Type': 'application/json'
					}
				})
				.then(function (response) {
					// manejar respuesta exitosa
					console.log(response);
					if (response.data.resultado === "OK") {
						setSuccesPost(succesPost)
					}
				})
				.catch(function (error) {
					// manejar error
					console.log(error);
				})
				.finally(function () {
					// siempre sera executado
				}
				);
		});
	}

	//////actualizar U
	const actualizarFinca = () => {
		upadteJson.map((finca, index) => {
			console.log("log actualizar", finca)
			let idFinca = finca.idFinca;
			axios.post(url + 'crops/16073/farms/' + idFinca, finca,
				{
					headers: {
						'Authorization': autorization,
						'dateString': dateString,
						'Content-Type': 'application/json'
					}
				})
				.then(function (response) {
					// manejar respuesta exitosa
					console.log(response.data);
					if (response.data.resultado === "OK") {
						//   alert("Persona Actualizada correctamente");
					}
				})
				.catch(function (error) {
					// manejar error
					console.log(error);
				})
				.finally(function () {
					// siempre sera executado
				});

		})
	}

	/////eliminar D
	const eliminarPersona = (socio) => {
		let idFinca = socio.idFinca;
		let idOrganizacion = socio.idOrganizacion;
		console.log(idFinca, idOrganizacion);

		axios.delete(url + 'crops/16073/farms/' + idFinca,
			{
				headers: {
					'Authorization': autorization,
					'dateString': dateString,
					'Content-Type': 'application/json'
				}
			})
			.then(function (response) {
				// manejar respuesta exitosa
				console.log(response);
				if (response.data.resultado === "OK") {
					//    alert("Persona con id: "+idPersona+" ha sido eliminado correctamente");
				}
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado}
			});
	}

	/// funcion para pasar el archivo json a un array de json desde el input file
	const handleFileChange = (event) => {
		const file = event.target.files[0]; // Obtén el primer archivo seleccionado (puedes manejar múltiples archivos si lo deseas)

		if (file) {
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onload = (e) => {
				try {
					const parsedData = JSON.parse(e.target.result);
					setJsonData(parsedData);
					uploadList(parsedData);
				} catch (error) {
					console.error('Error al analizar el archivo JSON:', error);
					setJsonData(null);
				}
			};

			reader.readAsText(file);
		} else {
			setSelectedFile(null);
			setJsonData(null);
		}
	};
	///funcion recorre el array y por cada json hace una peticion http post

	const uploadList = (data) => {
		data.map((jdata, index) => {
			const socioLoaded = fincas.find((finca) => finca.idFincaExt == jdata.idFincaExt);
			if (socioLoaded) {
				jdata.idFinca = socioLoaded.idFinca;
				let temp = [...upadteJson, jdata];
				upadteJson.push(jdata);
				setUpdateJson(temp);
				// actualizarFinca(socioLoaded)
			} else {
				let temp = [...addJson, jdata];
				addJson.push(jdata);
				setAddJson(temp);
				// addFinca(jdata)
			}
		}
		)
	}

	///eliminar del array de previsualicado

	const delJson = (arrJson, id) => {
		if (arrJson === "upadteJson") {
			setUpdateJson(upadteJson => upadteJson.filter((data) => data.otraReferencia != id));
		} else if (arrJson === "addJson") {
			setAddJson(addJson => addJson.filter((data) => data.otraReferencia != id));
		}


	}

	///funcion recorre el array y por cada json hace una peticion http post

	const delList = () => {
		if (fincas.map) {
			fincas.map((socio, index) => (
				eliminarPersona(socio)
			));
		} else {
		}
	}
	return (
		<div>
			<Header></Header>

			<div className='flex-container'>
				<div className='title'>
					Fincas
				</div>
			</div>
			<div className='button back' onClick={() => Navigate("/")}>
				<KeyboardBackspaceIcon fontSize='large' />&nbsp;Volver
			</div>
			{(update === true) && (
				<div className='flex-container'>
					<div className='header'>
						EL archivo tiene dentro el nombre: {nameJson};
					</div>
				</div>
			)}
			<div className='home-view-posts'>
				{(añadir === false) && (
					<>
						<div className='button' onClick={() => setAñadir(true)}>
							Añadir Archivo Fincas
						</div>
						{(borrarFincas === false) && (
							<div className='button' onClick={() => setBorrarFincas(true)}>
								Borrar todas las Fincas
							</div>
						)}
						{(borrarFincas === true) && (
							< div className='button' onClick={() => delList()}>
								Confirmar Borrado de todos las Fincas
							</div>
						)}
					</>
				)}
			</div>
			<div className='flex-container'>
				<div className='header'>
					Listado de {fincas.length} Fincas dadas de alta en Visual
				</div>
			</div>
			{(loaded === false) && (
				<div className='cardsContainer'>
					<div className='loading-spinner'>
					</div>
				</div>
			)}
			{(añadir === true) && (
				<div className='flex'>
					<div className='flex-container'>
						<div className=' upload-box '>
							<h4>
								Suelta aqui el archivo &nbsp;
							</h4>
							<div>
								<input id="datos" type="file" accept="application/JSON" onChange={(e) => handleFileChange(e)}></input>
							</div>
							<div className='delete-person-button' onClick={() => { setAñadir(false); setAddJson([]); setUpdateJson([]); setJsonData([]); }}>
								cancelar
							</div>
						</div>
					</div>
					<>
						{/* previsualizado de carga  */}
						{(jsonData !== null && (
							<div className='flex-container'>
								<div className='option-container'>
									{upadteJson.length > 0 && (
										<div className='uploadRow'>
											<div className='sub-header'>
												<div className='button' onClick={() => actualizarFinca()}>
													Actualizar {upadteJson.length} fincas
												</div>
											</div>
											{upadteJson.map((socio, index) => (
												<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
													<div className='preCard'>
														<FincaCard data={socio} socio={socio} />
														<div className='flex-container'>
															<div className='delete-person-button' onClick={() => delJson("upadteJson", socio.otraReferencia)}>
																<DeleteForeverIcon fontSize='medium' />
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									)}
									{addJson.length > 0 && (
										<div className='addRow'>
											<div className='sub-header'>
												<div className='button' onClick={() => addFinca()}>
													Subir {addJson.length} Nuevas Fincas a la base de datos
												</div>
											</div>
											{addJson.map((socio, index) => (
												<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
													<div className='preCard'>
														<FincaCard data={socio} socio={socio} />
														<div className='flex-container'>
															<div className='delete-person-button' onClick={() => delJson("addJson", socio.otraReferencia)}>
																<DeleteForeverIcon fontSize='medium' />
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						))}
					</>
				</div>
			)}

			{(loaded === true) && (<div className='cardsContainer'>
				{fincas.map((socio, index) => (
					<div key={index}>
						<div className='fincaCard'>
							<FincaCard data={socio} socio={socio} />
							<div className='flex-container'>
								<div className='delete-person-button' onClick={() => eliminarPersona(socio)}>
									<DeleteForeverIcon fontSize="medium" />
								</div>
								<div className='info-plantaciones-button' onClick={() => Navigate("/plantaciones-finca/" + socio.idFinca + "")}>
									Plantaciones
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			)}
		</div>
	)
}
