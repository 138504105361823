import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ParcelaCard } from './ParcelaCard';
import { Header } from '../global/Header';
import LinearWithValueLabel from '../../utils/LinearProgressWithLabel.jsx'
import { BarraProgreso } from '../../utils/BarraProgreso.jsx';
import Notificacion from '../../utils/Notificacion.jsx'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Swal from 'sweetalert2';


export const Parcelas = () => {

	//global
	const Navigate = useNavigate();

	//util
	const [parcelas, setParcelas] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [upadteJson, setUpdateJson] = useState([]);
	const [addJson, setAddJson] = useState([]);
	const [showLoadBar, setSHowLoadBar] = useState(false);

	// const [json, setJson] = useState(testjson);
	const [update, setUpdate] = useState(false);
	const [nameJson, setNameJson] = useState("");
	const [añadir, setAñadir] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);
	const [jsonData, setJsonData] = useState(null);
	const [succesPost, setSuccesPost] = useState([]);
	const [borrarParcelas, setBorrarParcelas] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [alertas, setAlertas] = useState([]);

	const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
	const autorization = ('2YOG3dgN81JmHgODPi+2evMrb55QrgtueoBz2IMCuzE=');
	const dateString = ('2024/22/0418:27:13');



	useEffect(() => {
		loadParcelas();
	}, [])

	// useEffect(() => {
	//   console.log("hi"); 
	//   setUpdate(true);
	//   setNameJson(testjson[0].nombre); 
	// }, [testjson, setUpdate===true]);

	//cargar L
	const loadParcelas = () => {
		axios.get(url + "crops/16073/farms", {
			headers: {
				'Authorization': autorization,
				'dateString': dateString,
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				setParcelas(response.data.data);
				setLoaded(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const listadoFaltaSigpac = async () => {
		let sinSigpac = [];

		try {
			for (let i = 0; i < parcelas.length; i++) {
				const parcela = parcelas[i];
				const response = await axios.get(url + "crops/16073/farms/" + parcela.idFinca + "/sigpacplots", {
					headers: {
						'Authorization': autorization,
						'dateString': dateString,
						'Content-Type': 'application/json'
					}
				});
				if (response.data.resultado === "OK") {
					console.log(response.data.data.length);
					if (response.data.data.length == 0) {
						console.log(parcela.idFincaExt);
						sinSigpac.push(parcela.idFincaExt);
					}


				}
			}

			console.log("Listado de parcelas sin Sigpac:", sinSigpac);
		} catch (error) {
			console.error("Error en la consulta:", error);
		}
		console.log(sinSigpac)
	}


	//////Crear C
	const addFinca = async () => {
		setSHowLoadBar(true);
		const totalParcelas = addJson.length;
		let parcelasProcesadas = 0;
		for (let index = 0; index < addJson.length; index++) {
			const parcela = addJson[index];

			const parcelasSigpac = [...parcela.sigpac];
			parcela.sigpac = [];
			try {
				const response = await axios.post(
					url + 'crops/16073/farms',
					parcela,
					{
						headers: {
							'Authorization': autorization,
							'dateString': dateString,
							'Content-Type': 'application/json'
						}
					}
				);

				// Manejar respuesta exitosa
				console.log(response.data, "parcela", parcela.idFincaExt, " ", index);
				if (response.data.resultado === "OK") {
					let temp = { ...parcela }
					temp.idFinca = response.data.data.idFinca;
					parcelas.push(temp);
					superficiesigpac(parcelasSigpac, response.data.data.idFinca);
				} else {
					let temp = [...alertas];
					temp.push({ mensaje: "error al subir parcela " + parcela.idFincaExt });
					setAlertas(temp);
					console.log("Error al subir parcela " + parcela.idFincaExt);
				}
			} catch (error) {
				// Manejar error
				console.log(error);
			} finally {
				parcelasProcesadas++;
				const progress = parseInt((parcelasProcesadas / totalParcelas * 100));
				setCurrentIndex(progress); // Actualiza el estado del progreso
			}
		}
	};

	const superficiesigpac = async (parcelasSigpac, idFinca) => {
		for (let index = 0; index < parcelasSigpac.length; index++) {
			const parcelaSigpac = parcelasSigpac[index];
			try {
				const response = await axios.post(
					url + 'crops/16073/farms/' + idFinca + '/sigpacplots',
					parcelaSigpac,
					{
						headers: {
							'Authorization': autorization,
							'dateString': dateString,
							'Content-Type': 'application/json'
						}
					}
				);

				// Manejar respuesta exitosa
				if (response.data.resultado === "OK") {
					console.log(response.data);
				}
			} catch (error) {
				// Manejar error
				console.log(error);
			}
		}


	}
	//////actualizar U
	const actualizarFinca = async () => {
		setSHowLoadBar(true);
		const totalParcelas = upadteJson.length;
		let parcelasProcesadas = 0;
		for (const parcela of upadteJson) {
			console.log("log actualizar", parcela);
			let idFinca = parcela.idFinca;
			try {
				const response = await axios.post(
					url + 'crops/16073/farms/' + idFinca,
					parcela,
					{
						headers: {
							'Authorization': autorization,
							'dateString': dateString,
							'Content-Type': 'application/json'
						}
					}
				);
				console.log(response.data);
				if (response.data.resultado === "OK") {
					// alert("Persona Actualizada correctamente");
					let temp = [...parcelas];

				}
			} catch (error) {
				console.log(error);
			}
			finally {
				parcelasProcesadas++;
				const progress = parseInt((parcelasProcesadas / totalParcelas * 100));
				setCurrentIndex(progress); // Actualiza el estado del progreso

				// if(progress == 100) setUpdateJson([]); 
			}
		}
		loadParcelas();
	};

	/////eliminar D
	const eliminarFinca = (socio) => {
		let idFinca = socio.idFinca;
		let idOrganizacion = socio.idOrganizacion;
		console.log(idFinca, idOrganizacion);

		Swal.fire({
			title: "Estas seguro/a?",
			text: "Vas a Eliminar permanetemente la parcela "+socio.finca+ "!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: '#83b600',
			cancelButtonColor: '#gray',
			confirmButtonText: "SI, Eliminalo!",
			cancelButtonText: "No, no lo Elimines"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(url + 'crops/16073/farms/' + idFinca,
					{
						headers: {
							'Authorization': autorization,
							'dateString': dateString,
							'Content-Type': 'application/json'
						}
					})
					.then(function (response) {
						// manejar respuesta exitosa
						console.log(response);
						if (response.data.resultado === "OK") {
							Swal.fire({
								title: "Parcela Eliminada!",
								text: "La parcela" + socio.finca + " ha sido eliminado.",
								icon: "success",
								timer: 2000,
								confirmButtonColor: '#83b600',
							});
							setParcelas(parcelas.filter(parcela => parcela.idFinca !== idFinca))
							// alert("Persona con id: "+idFinca+" ha sido eliminado correctamente");
						}
					})
					.catch(function (error) {
						// manejar error
						console.log(error);
					})
					.finally(function () {
						// siempre sera executado}
					});
			}
		});
	}

	/// funcion para pasar el archivo json a un array de json desde el input file
	const handleFileChange = (event) => {
		const file = event.target.files[0]; // Obtén el primer archivo seleccionado (puedes manejar múltiples archivos si lo deseas)

		if (file) {
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onload = (e) => {
				try {
					const parsedData = JSON.parse(e.target.result);
					setJsonData(parsedData);
					uploadList(parsedData);
				} catch (error) {
					console.error('Error al analizar el archivo JSON:', error);
					setJsonData(null);
				}
			};

			reader.readAsText(file);
		} else {
			setSelectedFile(null);
			setJsonData(null);
		}
	};
	///funcion recorre el array y por cada json hace una peticion http post

	const uploadList = (data) => {
		data.map((jdata, index) => {
			const socioLoaded = parcelas.find((finca) => finca.idFincaExt == jdata.idFincaExt);
			if (socioLoaded) {
				jdata.idFinca = socioLoaded.idFinca;
				let temp = [...upadteJson, jdata];
				upadteJson.push(jdata);
				setUpdateJson(temp);
				// actualizarFinca(socioLoaded)
			} else {
				let temp = [...addJson, jdata];
				addJson.push(jdata);
				setAddJson(temp);
				// addFinca(jdata)
			}
		}
		)
	}

	///eliminar del array de previsualicado

	const delJson = (arrJson, id) => {
		if (arrJson === "upadteJson") {
			setUpdateJson(upadteJson => upadteJson.filter((data) => data.otraReferencia != id));
		} else if (arrJson === "addJson") {
			setAddJson(addJson => addJson.filter((data) => data.otraReferencia != id));
		}

	}

	///funcion recorre el array y por cada json hace una peticion http post

	const delList = () => {
		if (parcelas.map) {
			parcelas.map((socio, index) => (
				eliminarFinca(socio)
			));
		} else {
		}
	}
	return (
		<div>
			<Header></Header>
			<div className='flex-container'>


				<div className='title'>
					Parcelas
				</div>
				{/* <div className='button' onClick={() => listadoFaltaSigpac()}>
					OPA
				</div> */}

			</div>
			<div className='button back' onClick={() => Navigate("/")}>
				<KeyboardBackspaceIcon fontSize='large' />&nbsp;Volver
			</div>
			{(update === true) && (
				<div className='flex-container'>
					<div className='header'>
						EL archivo tiene dentro el nombre: {nameJson};
					</div>
				</div>
			)}
			<div className='home-view-posts'>
				{(añadir === false) && (
					<>
						<div className='button' onClick={() => setAñadir(true)}>
							Añadir Archivo Parcelas
						</div>
						{(borrarParcelas === false) && (
							<div className='button' onClick={() => setBorrarParcelas(true)}>
								Borrar todas las Parcelas
							</div>
						)}
						{(borrarParcelas === true) && (
							<div className='button' onClick={() => delList()}>
								Confirmar Borrado de todos las Parcelas
							</div>
						)}
					</>
				)}
			</div>
			<div className='flex-container'>
				<div className='header'>
					Listado de {parcelas.length} Parcelas dadas de alta en Visual
				</div>
			</div>
			{(loaded === false) && (
				<div className='cardsContainer'>
					<div className='loading-spinner'>
					</div>
				</div>
			)}
			{(añadir === true) && (
				<div className='flex'>
					<div className='flex-container'>
						<div className=' upload-box '>
							<h4>
								Suelta aqui el archivo &nbsp;
							</h4>
							<div>
								<input id="datos" type="file" accept="application/JSON" onChange={(e) => handleFileChange(e)}></input>
							</div>
							<div className='delete-person-button' onClick={() => { setAñadir(false); setAddJson([]); setUpdateJson([]); setJsonData([]); }}>
								cancelar
							</div>
						</div>
					</div>
					<>
						{/* previsualizado de carga  */}
						{(jsonData !== null && (
							<div className='flex-container'>

								<div className='option-container'>
									{upadteJson.length > 0 && (
										<div className='uploadRow'>
											<div className='sub-header'>

												<div className='button' onClick={() => actualizarFinca()}>
													Actualizar {upadteJson.length} Parcelas
												</div>
												{showLoadBar === true && <BarraProgreso value={currentIndex} setValue={setCurrentIndex} setSHowLoadBar={setSHowLoadBar} setEstado={setUpdateJson} mensaje={"Actualizado"} />}


											</div>
											{upadteJson.map((socio, index) => (
												<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
													<div className='preCard'>
														<ParcelaCard data={socio} socio={socio} />
														<div className='flex-container'>
															<div className='delete-person-button' onClick={() => delJson("upadteJson", socio.otraReferencia)}>
																<DeleteForeverIcon fontSize='medium' />
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									)}
									{addJson.length > 0 && (
										<div className='addRow'>
											<div className='sub-header'>
												<div className='button' onClick={() => addFinca()}>
													Subir {addJson.length} Nuevas Parcelas a la base de datos
												</div>
												{showLoadBar === true && <BarraProgreso value={currentIndex} setValue={setCurrentIndex} setSHowLoadBar={setSHowLoadBar} setEstado={setAddJson} mensaje={"Parcelas Subidas"} />}

											</div>
											{addJson.map((socio, index) => (
												<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
													<div className='preCard'>
														<ParcelaCard data={socio} socio={socio} />
														<div className='flex-container'>
															<div className='delete-person-button' onClick={() => delJson("addJson", socio.otraReferencia)}>
																<DeleteForeverIcon fontSize='medium' />
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						))}
					</>
				</div>
			)}

			{(loaded === true) && (<div className='cardsContainer'>
				{parcelas.map((socio, index) => (
					<div key={index}>
						<div className='fincaCard'>
							<ParcelaCard data={socio} socio={socio} />
							<div className='flex-container'>
								<div className='delete-person-button' onClick={() => eliminarFinca(socio)}>
									<DeleteForeverIcon fontSize="medium" />
								</div>
								<div className='info-plantaciones-button' onClick={() => Navigate("/plantaciones-finca/" + socio.idFinca + "")}>
									Plantaciones
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			)}
			{alertas.length > 0 && (
				<div>
					{alertas.map((alerta, index) => (
						<div className='notificacion-container'>
							<div className="notificacion">
								<Notificacion index={index} mensaje={alerta.mensaje}></Notificacion><br></br>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
