import React from 'react'

export const ParcelaCard = (data) => {
  return (
    <>
    	<div><strong>idFinca:</strong> {data.data.idFinca}</div>
		{/* <div><strong>idProductor:</strong> {data.data.idProductor}</div>
		{/* <div><strong>productorNid:</strong> {data.data.productorNid}</div>
		<div><strong>idRepresentante:</strong> {data.data.idRepresentante}</div>
		<div><strong>representante:</strong> {data.data.representante}</div>
		<div><strong>representanteNid:</strong> {data.data.representanteNid}</div>
		<div><strong>idTecnico:</strong> {data.data.idTecnico}</div>
		<div><strong>tecnico:</strong> {data.data.tecnico}</div>
		<div><strong>idComercial:</strong> {data.data.idComercial}</div>
		<div><strong>comercial:</strong> {data.data.comercial}</div> */}
		{/* <div><strong>numSocio:</strong> {data.data.numSocio}</div>           */}
		{/* <div><strong>idTipoAgricultor:</strong> {data.data.idTipoAgricultor}</div>
		<div><strong>tipoAgricultor:</strong> {data.data.tipoAgricultor}</div> */}
		{/* <div><strong>otraReferencia:</strong> {data.data.otraReferencia}</div> */}
		{/* <div><strong>numPlantasHa:</strong> {data.data.numPlantasHa}</div>
		<div><strong>asegurada:</strong> {data.data.asegurada}</div>
		<div><strong>cartel:</strong> {data.data.cartel}</div>
		<div><strong>geom:</strong> {data.data.geom}</div>
		<div><strong>fechaBaja:</strong> {data.data.fechaBaja}</div>
		<div><strong>fechaExplotacion:</strong> {data.data.fechaExplotacion}</div>
		<div><strong>latitud:</strong> {data.data.latitud}</div>
		<div><strong>longitud:</strong> {data.data.longitud}</div>
		<div><strong>idEquipamientoAlmacen:</strong> {data.data.idEquipamientoAlmacen}</div>
		<div><strong>equipamientoAlmacen:</strong> {data.data.equipamientoAlmacen}</div>
		<div><strong>persons:</strong> {data.data.persons}</div> */}		
		{/* <div><strong>Variedades:</strong> {data.data.variedades}</div>
		<div><strong>idOrganizacion:</strong> {data.data.idOrganizacion}</div>
		<div><strong>organizacion:</strong> {data.data.organizacion}</div>
		<div><strong>idCropUnit:</strong> {data.data.idCropUnit}</div>
		<div><strong>idCultivo:</strong> {data.data.idCultivo}</div>
		<div><strong>idFinca:</strong> {data.data.idFinca}</div>
		<div><strong>idMunicipio:</strong> {data.data.idMunicipio}</div>
		<div><strong>idProductor:</strong> {data.data.idProductor}</div>
		<div><strong>idProvincia:</strong> {data.data.idProvincia}</div>
		<div><strong>tipoSuperficie:</strong> {data.data.tipoSuperficie}</div> */}
		<div><strong>finca:</strong> {data.data.idFincaExt}</div>	
		<div><strong>finca:</strong> {data.data.finca}</div>	 
		
 
		{/* <div><strong>Observaciones:</strong> {data.data.observaciones}</div> */}
		{/* <div><strong>productor:</strong> {data.data.productor}</div>
		<div><strong>organizacion:</strong> {data.data.organizacion}</div> */}
	  	<br></br>
		{/* <VariedadParcela idParcela={data.data.idFinca}></VariedadParcela> */}
    </>
  )
}
