import React from 'react'

export const SocioCard = (data, eliminarPersona, socio, actualizarPersona) => {
	// console.log(data.data);

  return (
	<div>
	{data.data.nombre ? <div className='card-title'><strong> {data.data.nombre}</strong></div> : null}
		<div className='card'>
			{data.data.idPersona ? <div className='card-element'><strong>idPersona:</strong> {data.data.idPersona}</div> : null}
			{data.data.telefono1 ?  <div className='card-element'><strong>telefono:</strong> {data.data.telefono1}</div> : null} 
			{data.data.direccion ? <div className='card-element'><strong>Direccion:</strong> {data.data.direccion}</div> : null}
			{data.data.esAsesor ?<div className='card-element'><strong>Es asesor:</strong> {data.data.esAsesor}</div> : null}
			{data.data.fechaAlta ? <div className='card-element'><strong>Fecha alta:</strong> {data.data.fechaAlta}</div> : null}
			{data.data.fechaMod ?<div className='card-element'><strong>Fecha mod:</strong> {data.data.fechaMod}</div> : null}
			{data.data.idOrganizacion ? <div className='card-element'><strong>idOrganizacion:</strong> {data.data.idOrganizacion}</div> : null}
			{data.data.idPais ? <div className='card-element'><strong>idPais:</strong> {data.data.idPais}</div> : null}
			{data.data.idTipo ? <div className='card-element'><strong>idTipo:</strong> {data.data.idTipo}</div> : null}
			{data.data.idUserAlta ? <div className='card-element'><strong>idUserAlta:</strong> {data.data.idUserAlta}</div> : null}
			{data.data.idUserMod ? <div className='card-element'><strong>idUserMod:</strong> {data.data.idUserMod}</div> : null} 
			{data.data.nid ? <div className='card-element'><strong>nid:</strong> {data.data.nid}</div> : null}
			{data.data.numFincasComercial ? <div className='card-element'><strong>numFincasComercial:</strong> {data.data.numFincasComercial}</div> : null}
			{data.data.numFincasProductor ? <div className='card-element'><strong>numFincasProductor:</strong> {data.data.numFincasProductor}</div> : null}
			{data.data.numFincasRepresentante ? <div className='card-element'><strong>numFincasRepresentante:</strong> {data.data.numFincasRepresentante}</div> : null}
			{data.data.numFincasRespFert ? <div className='card-element'><strong>numFincasRespFert:</strong> {data.data.numFincasRespFert}</div> : null}
			{data.data.numFincasTecnico ? <div className='card-element'><strong>numFincasTecnico:</strong> {data.data.numFincasTecnico}</div> : null}
			{data.data.numFincasTitularDun ? <div className='card-element'><strong>numFincasTitularDun:</strong> {data.data.numFincasTitularDun}</div> : null}
			{data.data.numSocio ? <div className='card-element'><strong>numSocio:</strong> {data.data.numSocio}</div> : null} 
			{data.data.organizacion ? <div className='card-element'><strong>organizacion:</strong> {data.data.organizacion}</div> : null}
			{data.data.pais ? <div className='card-element'><strong>pais:</strong> {data.data.pais}</div> : null}
			<br></br>
			
		</div>
    </div>
  )
}
