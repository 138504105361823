import React, { useEffect, useState } from 'react'
import { SocioCard } from './SocioCard';
import axios from 'axios';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { Header } from '../global/Header';
import { BarraProgreso } from '../../utils/BarraProgreso';
import Swal from 'sweetalert2';


export const Socios = () => {

	//global
	const Navigate = useNavigate();

	//util
	const [socios, setSocios] = useState([]);
	const [loaded, setLoaded] = useState(false);
	// const [json, setJson] = useState(testjson);
	const [upadteJson, setUpdateJson] = useState([]);
	const [addJson, setAddJson] = useState([]);
	const [añadir, setAñadir] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);
	const [jsonData, setJsonData] = useState(null);
	const [succesPost, setSuccesPost] = useState([]);
	const [borrarSocios, setBorrarSocios] = useState(false);
	const [showLoadBar, setSHowLoadBar] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);


	const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
	const autorization = ('gVk72OZIeYlMGKtAakYnBhkOxZtQdC0FeNX6KatpkSc=');
	const dateString = ('2024/2/1411:13:31');



	useEffect(() => {
		loadPersonas();
	}, [])

	//cargar L
	const loadPersonas = () => {
		//   axios.get('https://wstest.ws.visual.visualnacert.com/vnwebservices/user/270929/v3/persons', { 
		//         headers: {
		//           	'Authorization': 'g0pXrxd3PglJPthWoLUSIR+OHHNMcG3ST7dH7IOLWgQ=', 
		// 		    'dateString': '2023/9/318:54:42', 
		// 		    'Content-Type': 'application/json'
		//        }
		//         })
		//    .then(function (response) {
		//      // manejar respuesta exitosa
		//      setSocios(response.data.data);
		// 	 setLoaded(true);
		//    })
		//    .catch(function (error) {
		//      // manejar error
		//      console.log(error);
		//    })
		//    .finally(function () {
		//      // siempre sera executado
		//   });
		// }

		axios.get(url + 'persons', {
			headers: {
				'Authorization': autorization,
				'dateString': dateString,
				'Content-Type': 'application/json'
			}
		})
			.then(function (response) {
				// manejar respuesta exitosa
				// console.log(response)
				setSocios(response.data.data);
				setLoaded(true);
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado
			});
	}


	//////Crear C
	const addPersona = async () => {
		setSHowLoadBar(true);

		console.log(addJson);
		const totalSocios = addJson.length;
		let sociosProcesados = 0;

		for (let index = 0; index > addJson.length; index++) {
			const socio = addJson[index];
			let socioReady =
			{
				"idTipo": socio.idTipo,
				"idOrganizacion": socio.idOrganizacion,
				"idPersonaExt": socio.idPersonaExt,
				"nombre": socio.nombre,
				"apellidos": socio.apellidos,
				"nid": socio.nid,
				"telefono1": socio.telefono1,
				"telefono2": socio.telefono2,
				"email": socio.email,
				"numSocio": socio.numSocio,
				"codigoPersona": socio.codigoPersona,
				"idTipoAgricultor": socio.idTipoAgricultor,
				"idPais": socio.idPais,
				"idComunidad": socio.idComunidad,
				"idProvincia": socio.idProvincia,
				"idMunicipio": socio.idMunicipio,
				"direccion": socio.direccion,
				"numCarneRopo": socio.NumCarneRopo,
				"esAsesor": socio.esAsesor
			}

			try {
				const response = await axios.post(
					url + 'persons', socioReady,
					{
						headers: {
							'Authorization': autorization,
							'dateString': dateString,
							'Content-Type': 'application/json'
						}
					});
				if (response.data.resultado === "OK") {

				}


			} catch (error) {
				// Manejar error
				console.log(error);
			} finally {
				sociosProcesados++;
				const progress = parseInt((sociosProcesados / totalSocios * 100));
				setCurrentIndex(progress); // Actualiza el estado del progreso
			}

		}
		// addJson.map((socio, index) => {
		// 	let socioReady =
		// 	{
		// 		"idTipo": socio.idTipo,
		// 		"idOrganizacion": socio.idOrganizacion,
		// 		"idPersonaExt": socio.idPersonaExt,
		// 		"nombre": socio.nombre,
		// 		"apellidos": socio.apellidos,
		// 		"nid": socio.nid,
		// 		"telefono1": socio.telefono1,
		// 		"telefono2": socio.telefono2,
		// 		"email": socio.email,
		// 		"numSocio": socio.numSocio,
		// 		"codigoPersona": socio.codigoPersona,
		// 		"idTipoAgricultor": socio.idTipoAgricultor,
		// 		"idPais": socio.idPais,
		// 		"idComunidad": socio.idComunidad,
		// 		"idProvincia": socio.idProvincia,
		// 		"idMunicipio": socio.idMunicipio,
		// 		"direccion": socio.direccion,
		// 		"numCarneRopo": socio.NumCarneRopo,
		// 		"esAsesor": socio.esAsesor
		// 	}

		// 	axios.post(url + 'persons', socioReady,
		// 		{
		// 			headers: {
		// 				'Authorization': autorization,
		// 				'dateString': dateString,
		// 				'Content-Type': 'application/json'
		// 			}
		// 		})
		// 		.then(function (response) {
		// 			// manejar respuesta exitosa
		// 			// console.log(socioReady);
		// 			// console.log(response);
		// 			if (response.data.resultado === "OK") {
		// 				setSuccesPost(succesPost)
		// 			}
		// 		})
		// 		.catch(function (error) {
		// 			// manejar error
		// 			console.log(error);
		// 		})
		// 		.finally(function () {
		// 			// siempre sera executado
		// 		}
		// 		);
		// });
	}

	//////actualizar U
	const actualizarPersona = () => {

		upadteJson.map((socio, index) => {
			let parcelasProcesadas = 0;
			let idPersona = socio.idPersona;
			axios.post(url + 'persons/' + idPersona, socio,
				{
					headers: {
						'Authorization': autorization,
						'dateString': dateString,
						'Content-Type': 'application/json'
					}
				})
				.then(function (response) {
					// manejar respuesta exitosa
					console.log(response.data);
					if (response.data.resultado === "OK") {
						//   alert("Persona Actualizada correctamente");
					}
				})
				.catch(function (error) {
					// manejar error
					console.log(error);
				})
				.finally(function () {
					parcelasProcesadas++;
					const progress = parseInt((parcelasProcesadas / addJson.length * 100));
					setCurrentIndex(progress);
				});
		})
	}

	/////eliminar D
	const eliminarPersona = (socio) => {
		let idPersona = socio.idPersona;
		let idOrganizacion = socio.idOrganizacion;
		// console.log(idPersona, idOrganizacion);

		Swal.fire({
			title: "Estas seguro/a?",
			text: "Vas a Eliminar permanetemente el cliente " + socio.nombre + "!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: '#83b600',
			cancelButtonColor: '#gray',
			confirmButtonText: "SI, Eliminalo!",
			cancelButtonText: "No, no lo Elimines"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(url + 'persons/' + idPersona,
					{
						"idPersona": idPersona,
						"idOrganizacion": idOrganizacion,
						headers: {
							'Authorization': autorization,
							'dateString': dateString,
							'Content-Type': 'application/json'
						}
					})
					.then(function (response) {
						// manejar respuesta exitosa
						console.log(response);
						if (response.data.resultado === "OK") {
							Swal.fire({
								title: "Cliente Eliminado!",
								text: "El cliente" + socio.nombre +" con DNI/CIF "+ socio.nid+ " ha sido eliminado.",
								icon: "success",
								showConfirmButton: false,
								timer: 2000,
								confirmButtonColor: '#83b600',
							});
							let temp = [...socios];
							setSocios(temp.filter(tmp => tmp.idPersona !== response.data.data.idPersona))

							//    alert("Persona con id: "+idPersona+" ha sido eliminado correctamente");
						} else {
							Swal.fire({
								title: "No ha sido posible eliminar al Cliente!",
								text: "El cliente " + socio.nombre +" con DNI/CIF "+ socio.nid+ "no ha sido eliminado.",
								icon: "error",
								showConfirmButton: false,
								timer: 2000,
								confirmButtonColor: '#83b600',
							});
						}
					})
					.catch(function (error) {
						// manejar error
						console.log(error);
					})
					.finally(function () {
						// siempre sera executado}
					});
			}
		});
	}

	/// funcion para pasar el archivo json a un array de json desde el input file
	const handleFileChange = (event) => {
		const file = event.target.files[0]; // Obtén el primer archivo seleccionado (puedes manejar múltiples archivos si lo deseas)

		if (file) {
			setSelectedFile(file);

			const reader = new FileReader();

			reader.onload = (e) => {
				try {
					const parsedData = JSON.parse(e.target.result);
					setJsonData(parsedData);
					uploadList(parsedData);

				} catch (error) {
					console.error('Error al analizar el archivo JSON:', error);
					setJsonData(null);
				}
			};

			reader.readAsText(file);
		} else {
			setSelectedFile(null);
			setJsonData(null);
		}
	};
	///funcion recorre el array y por cada json hace una peticion http post

	const uploadList = (data) => {
		console.log("232323")
		data.map((jdata, index) => {
			const socioLoaded = socios.find((socio) => socio.nid == jdata.nid);
			console.log("socioLoaded", jdata, socios)
			if (socioLoaded) {
				jdata.idPersona = socioLoaded.idPersona;
				let temp = [...upadteJson, jdata];
				upadteJson.push(jdata);
				setUpdateJson(temp);
				// actualizarFinca(socioLoaded)
			} else {

				let temp = [...addJson, jdata];
				addJson.push(jdata);
				setAddJson(temp);
				// addFinca(jdata)
			}
		}
		)
	}

	///funcion recorre el array y por cada json hace una peticion http post

	const delList = () => {
		socios.map((socio, index) => (
			eliminarPersona(socio)
		));
	}

	const delJson = (arrJson, id) => {
		// console.log(arrJson, id.nid);
		if (arrJson === "upadteJson") {
			setUpdateJson(upadteJson => upadteJson.filter((data) => data.nid != id.nid));
		} else if (arrJson === "addJson") {
			setAddJson(addJson => addJson.filter((data) => data.nid != id.nid));
		}


	}
	return (
		<div>
			<Header></Header>

			<div className='flex-container'>
				<div className='title'>
					CLIENTES
				</div>
			</div>
			<div className='button back' onClick={() => Navigate("/")}>
				<KeyboardBackspaceIcon fontSize='large' />&nbsp;Volver
			</div>
			<div className='home-view-posts'>
				{(añadir === false) && (
					<>
						<div className='button' onClick={() => setAñadir(true)}>
							Añadir Archivo Clientes
						</div>
						{(borrarSocios === false) && (
							<div className='button' onClick={() => setBorrarSocios(true)}>
								Borrar todos los Clientes
							</div>
						)}
						{(borrarSocios === true) && (
							< div className='button' onClick={() => delList()}>
								Confirmar Borrado de todos los Clientes
							</div>
						)}
					</>
				)}
			</div>
			{(añadir === true) && (
				<div className='flex'>
					<div className='flex-container'>
						<div className=' upload-box '>
							<h4>
								Suelta aqui el archivo &nbsp;
							</h4>
							<div>
								<input id="datos" type="file" accept="application/JSON" onChange={(e) => handleFileChange(e)}></input>
							</div>
							<div className='delete-person-button' onClick={() => { setAñadir(false); setAddJson([]); setUpdateJson([]); setJsonData([]); }}>
								cancelar
							</div>
						</div>
					</div>
					{/* previsualizado de carga  */}
					{(jsonData !== null && (
						<div className='flex-container'>
							<div className='option-container'>
								{upadteJson.length > 0 && (
									<div className='uploadRow'>
										<div className='sub-header'>
											<div className='button' onClick={() => actualizarPersona()}>
												Acutalizar {upadteJson.length} Clientes
											</div>
										</div>
										{upadteJson.map((socio, index) => (
											<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
												<div className='preCard'>
													<SocioCard data={socio} socio={socio} />
													<div className='flex-container'>
														<div className='delete-person-button' onClick={() => delJson("upadteJson", socio)}>
															<DeleteForeverIcon fontSize='medium' />
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								)}
								{addJson.length > 0 && (
									<div className='addRow'>
										<div className='sub-header'>
											<div className='button' onClick={() => addPersona()}>
												Subir {addJson.length} Nuevos Clientes a la base de datos
											</div>
											{showLoadBar === true && <BarraProgreso value={currentIndex} setValue={setCurrentIndex} setSHowLoadBar={setSHowLoadBar} setEstado={setUpdateJson} mensaje={"Actualizado"} />}


										</div>
										{addJson.map((socio, index) => (
											<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
												<div className='preCard'>
													<SocioCard data={socio} socio={socio} />
													<div className='flex-container'>
														<div className='delete-person-button' onClick={() => delJson("addJson", socio)}>
															<DeleteForeverIcon fontSize='medium' />
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			)}

			<div className='flex-container'>
				<div className='header'>
					Listado de {socios.length} Clientes dados de alta en Visual
				</div>
			</div>
			{(loaded === true) && (<div className='cardsContainer'>
				{socios.map((socio, index) => (
					<div key={index}>
						<div className='socioCard'>
							<SocioCard data={socio} socio={socio} />
							<div className='flex-container'>
								<div className='delete-person-button' onClick={() => eliminarPersona(socio)}>
									<DeleteForeverIcon fontSize="medium" />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			)}
			{(loaded === false) && (
				<div className='cardsContainer'>
					<div className='loading-spinner'>
					</div>
				</div>
			)}

		</div>
	)
}

