import React, { useEffect, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import PersonIcon from '@mui/icons-material/Person';

export const VariedadCard = ({ data, variedadesYfincas, setVariedadesnesYfincas }) => {
	const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
	const autorization = ('gVk72OZIeYlMGKtAakYnBhkOxZtQdC0FeNX6KatpkSc=');
	const dateString = ('2024/2/1411:13:31');

	const [socio, setSocio] = useState({});

	useEffect(() => {
		// loadSocio();
		console.log("a", data);
	}, [])

	const conseguirVariedadesFinca = () => {
		axios.get(url + 'crops/16073/farms/' + data.idFinca,
			{
				headers: {
					'Authorization': autorization,
					'dateString': dateString,
					'Content-Type': 'application/json'
				}
			})
			.then(function (response) {
				// manejar respuesta exitosa
				  console.log(response.data.data.varieties[0].idFincaVariedad);
				eliminarVariedadFinca(response.data.data.varieties[0].idFincaVariedad);
				if (response.data.resultado === "OK") {
					//    alert("Persona con id: "+idPersona+" ha sido eliminado correctamente");
				}
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado}
			});

	}

	const eliminarVariedadFinca = (idFincaVariedad) => {
		axios.delete(url + 'crops/16073/farms/' + data.idFinca + '/farmsvarieties/' + idFincaVariedad,
			{
				headers: {
					'Authorization': autorization,
					'dateString': dateString,
					'Content-Type': 'application/json'
				}
			})
			.then(function (response) {
				// manejar respuesta exitosa
				console.log(response.data);
				if (response.data.resultado === "OK") {
					let temp = [...variedadesYfincas];
					console.log(data.idFinca)
					temp = temp.filter(item => item.idFinca !== data.idFinca);
					setVariedadesnesYfincas(temp)
				}
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado}
			});
	}


	return (
		<>
			{data.idFinca ? <div><strong>idFinca:</strong> {data.idFinca}</div> : null}
			{data.idFincaExt ? <div><strong>idFincaExt:</strong> {data.idFincaExt}</div> : null}
			{data.variedades ? <div><strong>Variedades:</strong> {data.variedades}</div> : null}
			{data.finca ? <div><strong>finca:</strong> {data.finca}</div> : null}
			{data.IDVariedad ? <div><strong>Variedades:</strong> {data.IDVariedad}</div> : null}
			{data.variedad ? <div><strong>Variedad:</strong> {data.variedad}</div> : null}
			<br></br>
			{data.idFinca && (
				<div className='flex-container'>
					<div className='delete-person-button' onClick={conseguirVariedadesFinca}>
						<DeleteForeverIcon />
					</div>
				</div>
			)}

		</>
	)
}
