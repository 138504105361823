import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
const Notificacion = ({ mensaje }) => {
    const [showNotification, setShowNotification] = useState(true);

    const handleClose = () => {
        setShowNotification(false);
    };

    return (
        <>
            {showNotification && (
                <div className="notification-container">
                    {mensaje.includes("error") ? (
                        <Alert
                            icon={<ErrorIcon fontSize="inherit" />}
                            severity="error"
                            onClose={handleClose}
                        >
                            {mensaje}
                        </Alert>
                    ) : (
                        <Alert
                            icon={<CheckIcon fontSize="inherit" />}
                            severity="success"
                            onClose={handleClose}
                        >
                            {mensaje}
                        </Alert>
                    )}
                </div>
            )}
        </>
    );
};

export default Notificacion;
