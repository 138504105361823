import React, { useEffect, useState } from 'react';
import axios from 'axios';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { VariedadCard } from './VariedadCard';
import { Fincas } from '../../fincas/Fincas';
import { PanelVar } from './PanelVar';

export const Variedades = () => {

	//global
	const Navigate = useNavigate();

	//util
	const [variedades, setVariedades] = useState([]);
	const [variedadesYfincas, setVariedadesnesYfincas] = useState([]);
	const [parcelas, setFincas] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [upadteJson, setUpdateJson] = useState([]);
	const [addJson, setAddJson] = useState([]);
	const [añadir, setAñadir] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);
	const [jsonData, setJsonData] = useState(null);
	const [succesPost, setSuccesPost] = useState([]);
	const [borrarSocios, setBorrarSocios] = useState(false);
	const [panelVariedades, setPanelVariedades] = useState(false);

	const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
	const autorization = ('gVk72OZIeYlMGKtAakYnBhkOxZtQdC0FeNX6KatpkSc=');
	const dateString = ('2024/2/1411:13:31');

	useEffect(() => {
		loadFincas();
		loadVariedades();
	}, [])

	useEffect(() => {
		loadVariedadesFinca();
	}, [parcelas.length > 0])

	const handleFileChange = (event) => {
		const file = event.target.files[0]; // Obtén el primer archivo seleccionado (puedes manejar múltiples archivos si lo deseas)

		if (file) {
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onload = (e) => {
				try {
					const parsedData = JSON.parse(e.target.result);
					setJsonData(parsedData);
					uploadList(parsedData);
					console.log(parsedData)
				} catch (error) {
					console.error('Error al analizar el archivo JSON:', error);
					setJsonData(null);
				}
			};

			reader.readAsText(file);
		} else {
			setSelectedFile(null);
			setJsonData(null);
		}
	};

	const uploadList = (data) => {
		data.map((jdata, index) => {
			console.log("jdata", jdata,);
			const socioLoaded = variedadesYfincas.find((finca) => finca.idFincaExt == jdata.idFincaExt);
			if (socioLoaded) {
				jdata.idFinca = socioLoaded.idFinca;
				let temp = [...upadteJson, jdata];
				upadteJson.push(jdata);
				setUpdateJson(temp);
				// actualizarFinca(socioLoaded)
			} else {
				let temp = [...addJson, jdata];
				addJson.push(jdata);
				setAddJson(temp);
				// addFinca(jdata)
			}
		}
		)
	}

	// const actualizarFinca = () => {
	// 	upadteJson.map((finca, index) => {
	// 		console.log("log actualizar", finca)
	// 		let idFinca = finca.idFinca;
	// 		axios.post(url + 'crops/15791/farms/' + idFinca, finca,
	// 			{
	// 				headers: {
	// 					'Authorization': autorization,
	// 					'dateString': dateString,
	// 					'Content-Type': 'application/json'
	// 				}
	// 			})
	// 			.then(function (response) {
	// 				// manejar respuesta exitosa
	// 				console.log(response.data);
	// 				if (response.data.resultado === "OK") {
	// 					//   alert("Persona Actualizada correctamente");
	// 				}
	// 			})
	// 			.catch(function (error) {
	// 				// manejar error
	// 				console.log(error);
	// 			})
	// 			.finally(function () {
	// 				// siempre sera executado
	// 			});

	// 	})
	// }

	const delJson = (arrJson, id) => {
		if (arrJson === "upadteJson") {
			setUpdateJson(upadteJson => upadteJson.filter((data) => data.idFincaExt != id));
		} else if (arrJson === "addJson") {
			setAddJson(addJson => addJson.filter((data) => data.idFincaExt != id));
		}
	}


	//cargar L Variedades
	const loadFincas = () => {
		axios.get(url + "crops/16073/farms", {
			headers: {
				'Authorization': autorization,
				'dateString': dateString,
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				setFincas(response.data.data);
				console.log(response.data.data);

			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const loadVariedades = () => {
		axios.get(url + "crops/16073/varieties", {
			headers: {
				'Authorization': autorization,
				'dateString': dateString,
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				setVariedades(response.data.data);
				console.log("variedades", response.data.data);
			})
			.catch((error) => {
				// console.log(error);
			});
	}

	//cargbar L fincas
	const loadVariedadesFinca = () => {
		const filteredFincas = parcelas.filter((finca) => finca.variedades !== undefined);

		setVariedadesnesYfincas(filteredFincas);
		setLoaded(true);
	};
	//añadir Variedades por finca

	const addVariedades = async () => {
		for (const fincaVar of addJson) {
			const variedadExistente = variedades.find(v => v.variedad === fincaVar.variedad);
			if (variedadExistente) {
				const parecelaVariedad = parcelas.find(parcela => parcela.idFincaExt == fincaVar.idFincaExt);
				if(parecelaVariedad){
					console.log({
						"idFinca": parecelaVariedad,
						"idVariedad": variedadExistente,
					}, fincaVar);
		
					const variedadAsociadaFinca = {
						"idFinca": parecelaVariedad.idFinca,
						"idVariedad": variedadExistente.idVariedad,
					};
		
					try {
						const response = await axios.post(url + 'crops/16073/farms/' + parecelaVariedad.idFinca + '/farmsvarieties', variedadAsociadaFinca, {
							headers: {
								'Authorization': autorization,
								'dateString': dateString,
								'Content-Type': 'application/json'
							}
						});
						console.log(response);
					} catch (error) {
						console.log(error);
					}
				}else {
					console.log("Finca no encontrada", fincaVar);
				}
				
			} else {
				console.log("Variedad no encontrada", fincaVar);
			}
		}
	}
	
	// Call the function

	//Eliminar D fincas

	const eliminarVariedades = (socio) => {
		// let idPersona = socio.idPersona;
		// let idOrganizacion = socio.idOrganizacion;
		// console.log(idPersona, idOrganizacion);

		// axios.delete(url + 'farm/' + idPersona,
		// 	{
		// 		"idPersona": idPersona,
		// 		"idOrganizacion": idOrganizacion,
		// 		headers: {
		// 			'Authorization': autorization,
		// 			'dateString': dateString,
		// 			'Content-Type': 'application/json'
		// 		}
		// 	})
		// 	.then(function (response) {
		// 		// manejar respuesta exitosa
		// 		console.log(response);
		// 		if (response.data.resultado === "OK") {
		// 			//    alert("Persona con id: "+idPersona+" ha sido eliminado correctamente");
		// 		}
		// 	})
		// 	.catch(function (error) {
		// 		// manejar error
		// 		console.log(error);
		// 	})
		// 	.finally(function () {
		// 		// siempre sera executado}
		// 	});
	}


	///funcion recorre el array y por cada json hace una peticion http post

	const delList = () => {
		variedades.map((variedadad, index) => (
			eliminarVariedades(variedadad)
		));
	}

	return (
		<div>
			<div className='flex-container'>
				<div className='title'>
					Variedades Parcelas
				</div>
			</div>
			<div className='button back' onClick={() => Navigate("/")}>
				<KeyboardBackspaceIcon fontSize='large' />&nbsp;Volver
			</div>
			<dvi className="flex-container">
				<div className='header'>
					Listado de {variedadesYfincas.length} Parcelas dadas de alta en Visual
				</div>
				<div className='header'>
					Variedades Cargadas: {variedades.length}
				</div>
			</dvi>
			<div className='home-view-posts'>
				{(añadir === false && panelVariedades === false) && (
					<>
						<div className='button' onClick={() => setAñadir(true)}>
							Añadir Archivo  Variedades Finca
						</div>
						{/* {(borrarSocios === false )&& (
						<div className='button' onClick={() => setBorrarSocios(true)}>
							Borrar todos las Variedades
						</div>
					)}
					{(borrarSocios === true )&& (
						< div className='button' onClick={() => delList()}>
							Confirmar Borrado de todos las Variedades
						</div>
					)} */}

					</>
				)}
				{(añadir === false && panelVariedades === false) && (
					<>
						<div className='button' onClick={() => setPanelVariedades(true)}>
							Panel de variedades
						</div>
						{/* {(borrarSocios === false )&& (
						<div className='button' onClick={() => setBorrarSocios(true)}>
							Borrar todos las Variedades
						</div>
					)}
						{(borrarSocios === true )&& (
						< div className='button' onClick={() => delList()}>
							Confirmar Borrado de todos las Variedades
						</div>
					)} */}

					</>
				)}
			</div>
			{/* <div className='button' onClick={() => subirVariedades()}>
				Subir array variedades
			</div>	 */}


			{(loaded === false) && (
				<div className='cardsContainer'>
					<div className='loading-spinner' />
				</div>
			)}
			
			{(añadir === true) && (
				<div className='flex'>
					<div className='flex-container'>
						<div className=' upload-box '>
							<h4>
								Suelta aqui el archivo &nbsp;
							</h4>
							<div>
								<input id="datos" type="file" accept="application/JSON" onChange={(e) => handleFileChange(e)}></input>
							</div>
							<div className='delete-person-button' onClick={() => { setAñadir(false); setAddJson([]); setUpdateJson([]); setJsonData([]); }}>
								cancelar
							</div>
						</div>
					</div>
					<>
						{/* previsualizado de carga  */}
						{(jsonData !== null && (
							<div className='flex-container'>
								<div className='option-container'>
									{upadteJson.length > 0 && (
										<div className='uploadRow'>
											<div className='sub-header'>
												{/* <div className='button' onClick={() => actualizarFinca()}>
													Actualizar {upadteJson.length} fincas
												</div> */}
											</div>
											{upadteJson.map((socio, index) => (
												<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
													<div className='preCard'>
														<VariedadCard data={socio} socio={socio} />
														<div className='flex-container'>
															<div className='delete-person-button' onClick={() => delJson("upadteJson", socio.idFincaExt)}>
																<DeleteForeverIcon fontSize='medium' />
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									)}
									{addJson.length > 0 && (
										<div className='addRow'>
											<div className='sub-header'>
												<div className='button' onClick={() => addVariedades()}>
													Asociar {addJson.length} variedades a Fincas a la base de datos
												</div>
											</div>
											{addJson.map((socio, index) => (
												<>
													<div key={index} className='cardContainer'> {/* Nuevo contenedor */}
														<div className='preCard'>
															<VariedadCard data={socio} socio={index} />
															<div className='flex-container'>
																<div className='delete-person-button' onClick={() => delJson("addJson", socio.idFincaExt)}>
																	<DeleteForeverIcon fontSize='medium' />
																</div>
															</div>
														</div>
													</div>
												</>
											))}

										</div>
									)}
								</div>
							</div>
						))}
					</>
				</div>
			)}
			{(panelVariedades === true) && (
				<PanelVar  setPanelVariedades={setPanelVariedades} variedades={variedades}/>
			)}
			{(loaded === true) && (
				<div className='cardsContainer'>
					{variedadesYfincas.map((fincaVariedad => (
						<>
							<div className='preCard'>
								<VariedadCard data={fincaVariedad} variedadesYfincas={variedadesYfincas} setVariedadesnesYfincas={setVariedadesnesYfincas} />
							</div>
						</>
					)))}
				</div>
			)}
		</div>
	)
}
