import React, { useState } from 'react'
import { VariedadCard } from './VariedadCard';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import axios from 'axios';


export const PanelVar = ({ setPanelVariedades, variedades }) => {
    //global

    const url = ('https://ws-test.visualnacert.com/vnwebservices/user/275754/v3/');
	const autorization = ('gVk72OZIeYlMGKtAakYnBhkOxZtQdC0FeNX6KatpkSc=');
	const dateString = ('2024/2/1411:13:31');
    //util
    const [añadir, setAñadir] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [jsonData, setJsonData] = useState(null);
    const [upadteJson, setUpdateJson] = useState([]);
    const [addJson, setAddJson] = useState([]);
    //value
    const uploadList = (data) => {
        data.map((jdata, index) => {
            console.log("jdata", jdata);
            const variedadEncontrada = variedades.find((variedad) => variedad.variedad === jdata.variedad)
            if (variedadEncontrada) {
                console.log("variedad encontrada", jdata);

                let temp = [...upadteJson, jdata];
                upadteJson.push(jdata);
                setUpdateJson(temp);
                // 	// actualizarFinca(socioLoaded)
            } else {
                console.log("variedad no encontrada", jdata);

                let temp = [...addJson, jdata];
                addJson.push(jdata);
                setAddJson(temp);
                // 	// addFinca(jdata)
            }
        }
        )

    }

    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Obtén el primer archivo seleccionado (puedes manejar múltiples archivos si lo deseas)

        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const parsedData = JSON.parse(e.target.result);
                    setJsonData(parsedData);
                    uploadList(parsedData);
                    console.log("parsedData", parsedData)
                } catch (error) {
                    console.error('Error al analizar el archivo JSON:', error);
                    setJsonData(null);
                }
            };

            reader.readAsText(file);
        } else {
            setSelectedFile(null);
            setJsonData(null);
        }
    };


    const delJson = (arrJson, id) => {
        if (arrJson === "upadteJson") {
            setUpdateJson(upadteJson => upadteJson.filter((data) => data.variedad != id));
        } else if (arrJson === "addJson") {
            setAddJson(addJson => addJson.filter((data) => data.variedad != id));
        }
    }

    const addVariedades = async () => {
        for (const element of addJson) {
            try {
                const farmResponse = await axios.post(url + 'crops/16073/farms', {
                    finca: "Ficticia Variedad " + element.variedad,
                    idOrganizacion: 2477
                }, {
                    headers: {
                        'Authorization': autorization,
                        'dateString': dateString,
                        'Content-Type': 'application/json'
                    }
                });
    
                console.log(farmResponse.data);
    
                if (farmResponse.data.resultado === "OK") {
                    const idFinca = farmResponse.data.data.idFinca;
                    await nuevaVariedad(element, idFinca);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };
    
    const nuevaVariedad = async (element, idFinca) => {
        try {
            const varietyResponse = await axios.post(url + 'crops/16073/varieties', {
                "variedad": element.variedad
            }, {
                headers: {
                    'Authorization': autorization,
                    'dateString': dateString,
                    'Content-Type': 'application/json'
                }
            });
    
            console.log(varietyResponse.data);
    
            if (varietyResponse.data.resultado === "OK") {
                const idVariedad = varietyResponse.data.data.idVariedad;
                await asociarNuevaVariedadFinca(idVariedad, idFinca);
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const asociarNuevaVariedadFinca = async (idVariedad, idFinca) => {
        try {
            const associationResponse = await axios.post(url + 'crops/16073/farms/' + idFinca + '/farmsvarieties', {
                "idFinca": idFinca,
                "idVariedad": idVariedad
            }, {
                headers: {
                    'Authorization': autorization,
                    'dateString': dateString,
                    'Content-Type': 'application/json'
                }
            });
    
            console.log("idFinca", idFinca, "idVariedad", idVariedad, " ", associationResponse.data);
    
            if (associationResponse.data.resultado === "OK") {
                // Puedes agregar más lógica aquí si es necesario
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <div className='flex'>
            <div className='flex-container'>
                <div className='variedades-container'>
                    <div className='title'>
                        Variedades Dadas De Alta En Visual
                    </div>
                    <div className='variedades-header'>
                        <div className='button' onClick={() => setAñadir(true)}>
                            Añadir Archivo  Variedades Finca
                        </div>
                        <div className='button' onClick={() => setPanelVariedades(false)}>
                            x
                        </div>
                    </div>
                    {(añadir === true) && (
                        <div className='flex-center-100'>
                            <div className='flex'>
                                <div className='flex-container'>
                                    <div className=' upload-box '>
                                        <h4>
                                            Suelta aqui el archivo &nbsp;
                                        </h4>
                                        <div>
                                            <input id="datos" type="file" accept="application/JSON" onChange={(e) => handleFileChange(e)}></input>
                                        </div>
                                        <div className='delete-person-button' onClick={() => { setAñadir(false); setAddJson([]); setUpdateJson([]); setJsonData([]); }}>
                                            cancelar
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <>
                                {/* previsualizado de carga  */}
                                {console.log(jsonData)}
                                {(jsonData !== null && (
                                    <div className='flex-container'>
                                        <div className='option-container'>

                                            {console.log(addJson)}
                                            {addJson.length > 0 && (
                                                <div className='addRow'>
                                                    <div className='sub-header'>
                                                        <div className='button' onClick={() => addVariedades()}>
                                                            Subir {addJson.length} variedades Nuevas
                                                        </div>
                                                    </div>
                                                    {addJson.map((socio, index) => (
                                                        <>
                                                            <div key={index} className='cardContainer'> {/* Nuevo contenedor */}
                                                                <div className='preCard'>
                                                                    <VariedadCard data={socio} socio={index} />
                                                                    <div className='flex-container'>
                                                                        <div className='delete-person-button' onClick={() => delJson("addJson", socio.variedad)}>
                                                                            <DeleteForeverIcon fontSize='medium' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        </div>
                    )}
                    {variedades.map((variedad, index) => (
                        <div className='preCard' key={index}>
                            <div>Variedad {variedad.variedad}</div>
                            <div>idVariedad {variedad.idVariedad}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
